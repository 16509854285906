<template>
  <div>
      <b-card no-body>
        <b-row style="margin-right: 0px;">
            <b-col cols="8">
              <div  style="padding-left:30px">
                  <div>
                    <p style="font-weight: 600;font-size: 21px;line-height: 21px;color: #636363;padding-top:40px">{{revision.tfr}} <feather-icon icon="Edit3Icon" style="cursor: pointer;height: 15px;width: 15px; margin-right: 30px;" @click="$bvModal.show('editRevision')" v-show="(revision.revision != null) && ((revision.tfr_status != 'Quote in Progress') && (revision.tfr_status != 'Quote Completed') && (revision.tfr_status != 'Archived') && (revision.tfr_status != null))"/></p>
                  </div>
                  <div style="padding-top:30px">
                    <p style="font-weight: 600;font-size: 15px;line-height: 24px;color: #636363;">Test Fit <feather-icon icon="Edit3Icon" style="cursor: pointer;height: 15px;width: 15px; margin-right: 30px;" @click="$bvModal.show('editTestFit')" v-show="revision.tfr_status == 'Reviewing Test Fit'"  /></p>
                      <b-button variant="outline-primary" v-show="revision.tfr_status == null" v-b-modal.requestTestFit >
                        Submit Request
                      </b-button>
                      <b-button variant="outline-primary" v-show="revision.tfr_status == 'Awaiting Test Fit'" v-b-modal.attachTestFit>
                        Attach Test Fit
                      </b-button>
                      <div >
                        <b-button variant="outline-secondary" :href="revision.test_fit_pdf" target="_blank" role="presentation" :disabled="revision.test_fit_pdf == null"  v-show="(revision.tfr_status != 'Awaiting Test Fit') && (revision.tfr_status != null) "  >
                          <feather-icon icon="ExternalLinkIcon" />
                          View Test Fit
                        </b-button>
                        <b-button variant="outline-primary" v-b-modal.reviewTestFit style="margin-left:10px" v-show="revision.tfr_status == 'Reviewing Test Fit'"  >
                          Review Test Fit
                        </b-button>
                      </div>
                  </div>
                  <div style="padding-top:30px">
                    <p style="font-weight: 600;font-size: 15px;line-height: 24px;color: #636363;margin-bottom:10px">Quote</p>
                    <b-row style="margin-bottom:25px;" v-if="revision.quote_id != null">
                        <b-col md="6" align-self="center">
                            <label style="font-weight: 600 !important;font-size: 12px;line-height: 23px;color: #B9B9C3;letter-spacing: 0.6px;text-transform: uppercase;">Project $/sqft</label>
                            <h2 style="color: #6E6B7B;font-weight: 500 !important;font-size: 24px;line-height: 24px;">${{Number(revision.quote_totals.project_dollar_per_sqft).toLocaleString(undefined, { minimumFractionDigits: 2 })}}</h2>
                        </b-col>
                        <b-col md="6" align-self="center">
                            <label style="font-weight: 600 !important;font-size: 12px;line-height: 23px;color: #B9B9C3;letter-spacing: 0.6px;text-transform: uppercase;">Project Total</label>
                            <h2 style="color: #6E6B7B;font-weight: 500 !important;font-size: 24px;line-height: 24px;">${{Number(revision.quote_totals.project_total).toLocaleString(undefined, { minimumFractionDigits: 2 })}}</h2>
                        </b-col>
                    </b-row>
                    <b-button v-b-modal.modal-duplication variant="outline-primary" v-show="revision.tfr_status == 'Awaiting Quote'">
                        Create a Quote
                    </b-button>
                    <b-button  target="_blank" variant="outline-primary" v-show="(revision.quote_id != null)" :href="'/opportunities/' + opportunity.sf_opportunity_id + '/' + revision.quote_id">
                        View Quote
                    </b-button>
                      <!-- Dropdown -->
                      <b-dropdown v-if="(isAdmin == 1) && ((revision.quote_id != null))" no-caret toggle-class="p-0 pl-2" variant="link">
                          <template #button-content>
                              <feather-icon class="align-middle text-body" icon="MoreVerticalIcon" size="16" />
                          </template>
                          <b-dropdown-item target="_blank" @click="deleteQuote(revision.quote_id)">
                              <feather-icon icon="Trash2Icon" />
                              <span class="align-middle ml-50">Delete</span>
                          </b-dropdown-item>
                      </b-dropdown>
                    <span style="font-weight: 400;font-size: 12px;line-height: 18px;color: #B9B9C3;" v-show="(revision.tfr_status != 'Awaiting Quote') && (revision.quote_id == null)">A quote can be made after a test fit is approved.</span>
                  </div>
                  <div style="padding-top:40px;padding-bottom:50px">
                    <p style="font-weight: 600;font-size: 15px;line-height: 24px;color: #636363;margin-bottom:10px">Attachments</p>
                    <b-button variant="outline-secondary" :href="revision.attachments_folder_url==null? opportunity.attachments_folder_url: revision.attachments_folder_url" target="_blank" role="presentation" :disabled="revision.attachments_folder_url == null &&  opportunity.attachments_folder_url==null"  >
                      <feather-icon icon="ExternalLinkIcon" />
                      View Attachments Folder
                    </b-button>
                  </div>
                  <hr>                            
                  <div style="padding-top:40px">
                    <p style="font-weight: 600;font-size: 15px;line-height: 24px;color: #636363;margin-bottom:10px">Sales Notes</p>
                    <span style="font-weight: 400;font-size: 14px;line-height: 21px;color: #6E6B7B;    word-break: break-word;white-space: pre-line;">{{revision.notes}}</span>
                  </div>
                  <div style="padding-top:40px;padding-bottom:40px" v-show="(revision.tfr_status == 'Quote Completed')">
                    <p style="font-weight: 600;font-size: 15px;line-height: 24px;color: #636363;margin-bottom:10px">Estimator Notes</p>
                    <span style="font-weight: 400;font-size: 14px;line-height: 21px;color: #6E6B7B;    word-break: break-word;white-space: pre-line;">{{revision.estimator_notes}}</span>
                  </div>

              </div>
            </b-col>
            <b-col cols="4" style="border: 1px solid rgba(31, 41, 47, 0.05);    padding-left: 0px;    padding-right: 0px;">
                  <h5 style="font-weight: 600;font-size: 15px;line-height: 24px;color: #636363;padding-top:20px;padding-left:28px;padding-bottom:15px;border: 1px solid rgba(31, 41, 47, 0.05);margin-bottom: 0px;">Requirements 
                    <feather-icon icon="Edit3Icon" style="cursor: pointer;height: 15px;width: 15px; margin-right: 30px;" @click="$bvModal.show('tfr-update')" v-show="revision.tfr_status == null"  /></h5>
                  <b-list-group style="position:relative; overflow-y:auto; height:750px">
                    <b-list-group-item
                      v-for="(requirement, index) in revision.requirements"
                      :key="'req'+index"
                      tag="li"
                      style="border: 1px solid rgba(31, 41, 47, 0.05);padding-left: 28px;"
                    >
                      <b-row>
                        <b-col cols="2" style="padding-right:13px;font-weight: 400;font-size: 12px;line-height: 24px;color: #6E6B7B;">
                          {{requirement.order}}
                        </b-col>
                        <b-col cols="9" style="padding-left:0px;">
                          <div style="font-weight: 600;font-size: 14px;line-height: 21px;color: #636363;">{{ requirement.name }}</div>
                          <div style="font-weight: 400;font-size: 12px;line-height: 18px;color: #636363;white-space: pre-line;">{{ requirement.description }}</div>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-list-group>
            </b-col>
        </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BRow,
  BSpinner,
  BTable,
  BTooltip,
  VBModal,
  BBadge,
  BTab,
  BTabs,
  BCardText,
  BListGroup,
  BListGroupItem,
  VBToggle,
  BCollapse,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroupPrepend,
  BInputGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from 'moment'

export default {
    components: {
        BCard,
        BTable,
        BCol,
        BRow,
        BSpinner,
        BButton,
        BTooltip,
        BDropdown,
        BDropdownItem,
        BAvatar,
        BBadge,
        BTab,
        BTabs,
        BCardText,
        BListGroupItem,
        BCollapse,
        BFormGroup,
        BFormTextarea,
        BFormInput,
        BListGroup,
        BInputGroupPrepend,
        BInputGroup,
        ValidationProvider,
        ValidationObserver,
    },
    props: ['opportunity','tfrs','revisionProp'],
    directives: {
        'b-modal': VBModal,
        'b-toggle': VBToggle,
        Ripple,
    },
    data() {
      return {
        opportunityId: this.$route.params.id,
        userName: localStorage.getItem('name'),
        isAdmin: localStorage.getItem('isAdmin'), 
        isBusy: true,
        tableIsBusy: true,
        testFitLink:'',
        testFitState: null,
        modalIsBusy:false,
        revision: this.revisionProp,
      }
    },
    beforeUpdate() {
    },
    mounted() { 
    },
    methods: {
    getOpportunity() {
      this.$http.get(`/opportunities/${this.opportunityId}`)
        .then(response => {
          this.opportunity = response.data
          this.quotes = this.opportunity.quotes
          this.revision = this.opportunity.tfrs[0];
          this.selectedIndex = 0;
          this.selectedRequirements = this.revision.requirements;
          // console.log(this.quotes);
          this.isBusy = false
          this.tableIsBusy = false
          this.updateBreadcrumb()
          if(this.routeRevisionId){
            const index = this.opportunity.tfrs.map(e => e.tfr).indexOf(this.routeRevisionId);
            this.revision = this.opportunity.tfrs[index];
            this.selectedRequirements = this.revision.requirements;
            this.selectedIndex = index;
          }
        })
        .catch(error => {
          this.isBusy = false
          this.tableIsBusy = false
          this.showToast('danger', error.response.data.message)
        })
    },
    updateBreadcrumb() {
      this.$route.meta.breadcrumb[0].to = '/opportunities'
      this.$route.meta.breadcrumb[0].active = false
      this.$route.meta.breadcrumb[2].text = `${this.opportunity.address}, ${this.opportunity.geography} - ${this.opportunity.account_name}`
      this.$root.$emit('rerender-breadcrumb')
    },
    clicked(item) {
      const { id } = item
      this.$router.push(`/opportunities/${this.opportunityId}/${id}`)
      //window.open(`/opportunities/${this.opportunityId}/${id}`, '_blank');
    },
    deleteQuote(id) {
      this.tableIsBusy = true
      this.$http.delete(`/quote/${id}`)
        .then(response => {
          this.showToast('success', 'Quote Deleted Successfully', 'Notification')
          this.getOpportunity()
          this.tableIsBusy = false
        })
        .catch(error => {
          this.showToast('danger', 'Error Deleting Quote', 'Notification')
          this.tableIsBusy = false
        })
    },
     
    relativeTime(date) {
      return this.$dateF.formatDate(date);
    },
    formatDatetime(date) {
      return this.$dateF.formatDate(date);
    },
    initials(text) {
      const initial = text.match(/(\b\S)?/g).join('').match(/(^\S|\S$)?/g).join('')
        .toUpperCase()

      return initial
    },
    getQuoteLastUpdated(item) {
      if (item.updated_by == null) {
        if(item.created_by == null){
          return 'Sales Force'
        }
        return item.created_by
      }
      return item.updated_by
    },
    transformToJson(requirements) {
      return JSON.stringify(requirements)
    },
    selectedRevision(revision, index) {
      this.revision = revision;
      this.selectedRequirements = revision.requirements;
      this.selectedIndex = index;
    },
    onChange(event) {
        this.reorder()
    },
    reorder() {
        this.revision.requirements.forEach((item, index) => (item.order = (index + 1)))
    },
    aDayAgo(date) {
      var a = moment();
      var b = moment(date);
      return a.diff(b, 'days');
    },
    formattedDate(date) {
      return moment(date).format("MMM Do YYYY")
    },
    submitRequestModal(bvModalEvent) {
      this.$bvModal
        .msgBoxConfirm('Requesting a test fit will notify space planners and lock requirements for this revision.', {
          title: 'Request a Test Fit',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Continue',
          cancelTitle: 'Go Back',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          bodyClass: 'modal-padding',
        })
        .then(value => {

        })
    },
    badgeClass(status) {
      if(status == 'Awaiting Test Fit'){
        return 'awaiting-test-fit';
      } else if(status == 'Reviewing Test Fit'){
        return 'reviewing-test-fit'
      }else if(status == 'Archived'){
        return 'archived'
      }else if(status == 'Awaiting Quote'){
        return 'awaiting-quote'
      }else if(status == 'Quote in Progress'){
        return 'quote-in-progress'
      }else if((status == 'Submitted for Handover') || (status == 'Active')){
        return 'submitted-for-handover'
      }else if(status == 'Reviewing Quote'){
        return 'reviewing-quote'
      }else if(status == 'Sent to Client'){
        return 'sent-to-client'
      }else if(status == 'Hardened'){
        return 'hardened'
      }else if(status == 'Quote Completed'){
        return 'quote-completed'
      }
      
    },
    checkFormValidity() {
      const valid = this.$refs.attachTestFitForm.checkValidity()
      this.testFitState = valid
      return valid
    },
    resetModal() {
      this.testFitLink = ''
      this.testFitState = null
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleRequestTestFit(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleSubmitRequestTestFit()
    },
    handleReviewTestFit(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleReviewTestFit()
    },
    handleReviseTestFit(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleReviseTestFit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.modalIsBusy = true;
      const testFitLinkData = {
          testFitLink: this.testFitLink
      };

      this.$http.post(`/opportunities/${this.opportunityId}/tfr/${this.revision.id}/attach-test-fit`,testFitLinkData)
      .then(response => {
        this.modalIsBusy = false;
        this.revision = response.data.data;
        this.opportunity.tfrs[this.selectedIndex] = response.data.data;
        this.showToast('success', 'Sales will be notified to review.', 'Test Fit Submitted');
        // Hide the modal manually
        this.$nextTick(() => {
          //this.$bvModal.hide('attachTestFit')
          this.$refs['attachTestFit'].hide()
        })
      })
      .catch(error => {
        this.modalIsBusy = false;
        this.showToast('danger', error.response.data.message)
      })
    },
    handleSubmitRequestTestFit() {
      this.modalIsBusy = true;
      this.$http.post(`/opportunities/${this.opportunityId}/tfr/${this.revision.id}/request-test-fit`)
      .then(response => {
        this.modalIsBusy = false;
        this.revision = response.data.data;
        this.opportunity.tfrs[this.selectedIndex] = response.data.data;
        this.showToast('success', 'A test fit will be requested from space planners.', 'Test Fit Requested');
        this.$nextTick(() => {
          this.$refs['requestTestFit'].hide()
          //this.$bvModal.toggle('requestTestFit')
        })
      })
      .catch(error => {
        this.modalIsBusy = false;
        this.showToast('danger', error.response.data.message)
      })
    },
    handleReviewTestFit() {
      this.modalIsBusy = true;
      this.$http.post(`/opportunities/${this.opportunityId}/tfr/${this.revision.id}/review-test-fit`)
      .then(response => {
        this.modalIsBusy = false;
        this.revision = response.data.data;
        this.opportunity.tfrs[this.selectedIndex] = response.data.data;
        this.showToast('success', 'A quote will be requested.', 'Test Fit Approved');
        this.$nextTick(() => {
          this.$refs['reviewTestFit'].hide()
          //this.$bvModal.toggle('reviewTestFit')
        })
      })
      .catch(error => {
        this.modalIsBusy = false;
        this.showToast('danger', error.response.data.message)
      })
    },
    handleReviseTestFit() {
      this.modalIsBusy = true;
      this.$http.post(`/opportunities/${this.opportunityId}/tfr/${this.revision.id}/revise-test-fit`)
      .then(response => {
        this.modalIsBusy = false;
        this.revision = response.data.data;
        this.opportunity.tfrs[this.selectedIndex] = response.data.data;
        this.showToast('success', 'You can create a new test fit request at any time.', 'Test Fit Archived');
        this.$nextTick(() => {
          this.$refs['reviewTestFit'].hide()
          //this.$bvModal.toggle('reviewTestFit')
        })
      })
      .catch(error => {
        this.modalIsBusy = false;
        this.showToast('danger', error.response.data.message)
      })
    },

    removeTestFitLink() {
      this.modalIsBusy = true;
      this.$http.post(`/opportunities/${this.opportunityId}/tfr/${this.revision.id}/remove-test-fit`)
      .then(response => {
        this.modalIsBusy = false;
        this.revision = response.data.data;
        this.opportunity.tfrs[this.selectedIndex] = response.data.data;
        this.showToast('success', 'You can add the Test Fit Link again at any time.', 'Test Fit Removed');
        this.$nextTick(() => {
          this.$refs['editTestFit'].hide()
          //this.$bvModal.toggle('reviewTestFit')
        })
      })
      .catch(error => {
        this.modalIsBusy = false;
        this.showToast('danger', error.response.data.message)
      })
    },

    updateTestFitLink() {
      this.modalIsBusy = true;
      const testFitLinkData = {
          testFitLink: this.revision.test_fit_pdf
      };

      this.$http.post(`/opportunities/${this.opportunityId}/tfr/${this.revision.id}/attach-test-fit`,testFitLinkData)
      .then(response => {
        this.modalIsBusy = false;
        this.revision = response.data.data;
        this.opportunity.tfrs[this.selectedIndex] = response.data.data;
        this.showToast('success', 'Sales will be notified to review.', 'Test Fit Submitted');
        // Hide the modal manually
        this.$nextTick(() => {
          //this.$bvModal.hide('attachTestFit')
          this.$refs['editTestFit'].hide()
        })
      })
      .catch(error => {
        this.modalIsBusy = false;
        this.showToast('danger', error.response.data.message)
      })
    },
    
    validationTestFitLink() {
      return new Promise((resolve, reject) => {
        this.$refs.testFitLinkRef.validate().then(success => {
          if (success) {
            this.modalIsBusy = true;
            this.$http.post(`/opportunities/${this.opportunityId}/tfr/${this.revision.id}/attach-test-fit`,testFitLinkData)
            .then(response => {
              this.modalIsBusy = false;
              this.revision = response.data.data;
              this.opportunity.tfrs[this.selectedIndex] = response.data.data;
              this.showToast('success', 'Sales will be notified to review.', 'Test Fit Submitted');
              // Hide the modal manually
              this.$nextTick(() => {
                this.$refs['attachTestFit'].hide()
                //this.$bvModal.toggle('attachTestFit')
              })
              resolve(true)
            })
            .catch(error => {
              this.modalIsBusy = false;
              this.showToast('danger', error.response.data.message)
            })
          } else {
             reject()
          }
          
        })
      })
    },

    validationEditTestFitLink() {
      return new Promise((resolve, reject) => {
        this.$refs.editTestFitLinkRef.validate().then(success => {
          if (success) {
            this.modalIsBusy = true;
            const testFitLinkData = {
                testFitLink: this.revision.test_fit_pdf
            };

            this.$http.post(`/opportunities/${this.opportunityId}/tfr/${this.revision.id}/attach-test-fit`,testFitLinkData)
            .then(response => {
              this.modalIsBusy = false;
              this.revision = response.data.data;
              this.opportunity.tfrs[this.selectedIndex] = response.data.data;
              this.showToast('success', 'Sales will be notified to review.', 'Test Fit Submitted');
              // Hide the modal manually
              this.$nextTick(() => {
                //this.$bvModal.hide('attachTestFit')
                this.$refs['editTestFit'].hide()
              })
              resolve(true)
            })
            .catch(error => {
              this.modalIsBusy = false;
              this.showToast('danger', error.response.data.message)
            })
          } else {
             reject()
          }
          
        })
      })
    },
    updateRevisionName() {
      return new Promise((resolve, reject) => {
        this.$refs.editRevisionRef.validate().then(success => {
          if (success) {
            this.modalIsBusy = true;
            const revisionData = {
                revision: this.revision.revision
            };

            this.$http.post(`/opportunities/${this.opportunityId}/tfr/${this.revision.id}/update-revision-name`,revisionData)
            .then(response => {
              this.modalIsBusy = false;
              this.revision = response.data.data;
              this.opportunity.tfrs[this.selectedIndex] = response.data.data;
              this.showToast('success', '', 'Revision Name Updated');
              // Hide the modal manually
              this.$nextTick(() => {
                //this.$bvModal.hide('attachTestFit')
                this.$refs['editRevision'].hide()
              })
              resolve(true)
            })
            .catch(error => {
              this.modalIsBusy = false;
              this.showToast('danger', error.response.data.message)
            })
          } else {
             reject()
          }
          
        })
      })
    },
    updatedTfr(variable) {
      this.revision = variable;
      this.opportunity.tfrs[this.selectedIndex] = variable;
      this.selectedRequirements = variable.requirements;
      this.showToast('success', 'Revision Updated Successfully', 'Revision Updated');
      this.$refs['tfr-update'].hide()
    },
    createdTfr(variable) {
      this.showToast('success', 'Revision Created Successfully', 'Revision Created');
      this.$refs['tfr-creation'].hide()
      this.revision = variable.tfr;
      this.opportunity = variable.opportunity
      var tfrId = this.revision.id;
      var index = this.opportunity.tfrs.indexOf(this.opportunity.tfrs.filter(function(item) { return item.ID == tfrId })[0]);
      this.selectedRequirements = this.revision.requirements;
      this.selectedIndex = index;
    },
    errorModal(variable) {
      this.showToast('danger', 'Contact Digital for support.','Something went wrong')
    },
  },
}
</script>

<template>
  <div class="my-2">
    <b-card>
      <div class="mx-2 mt-1">
        <b-row>
          <b-col cols="10">
            <h2>Construction Tender</h2>
            <p class="grey_text" style="font-weight: 400">
              Tender data for all approved quotes
            </p></b-col
          >
          <b-col cols="2">
            <b-button
              @click="exportTender"
              class="w-100"
              style="padding: 15px; margin-top: 7px"
              variant="outline-secondary"
            >
              <uil-file-export size="15" /> &nbsp; Export
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BButton, BCard, BCol, BRow } from "bootstrap-vue";
import { UilFileExport } from "@iconscout/vue-unicons";
export default {
  components: {
    BButton,
    BCard,
    BCol,
    BRow,
    UilFileExport,
  },
  props: {
    address: {
      type: String,
    },
  },
  methods: {
    exportTender() {
      const fileName = `${this.address} -  Tender Export CO.xlsx`;
      this.$http({
        url: `/opportunities/${this.$route.params.id}/tender-export-co`,
        method: "GET",
        responseType: "arraybuffer",
      }).then((res) => {
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <b-col cols="12" md="12" xl="12">
      <b-tabs pills>
        <b-tab title="Contract" class="printable">
          <b-col align-self="end" cols="12" style="text-align: end; padding-bottom: 20px">
            <b-button
              :href="`/opportunities/${opportunityId}/compare-quotes/`"
              variant="outline-secondary"
              style="margin-right: 10px"
              v-if="opportunity.quotes.length >= 2"
            >
              Compare Quotes
            </b-button>
          </b-col>
          <b-card class="invoice-preview-card" no-body>
            <ccdc :tfrs="tfrs" :opportunity="opportunity" :revisionProp="revisionProp" />
          </b-card>
        </b-tab>
        <b-tab title="Change orders" class="notprintable">
          <change-orders :opportunity="opportunity" :revisionProp="revisionProp" />
        </b-tab>
        <b-tab title="Finance" class="notprintable">
          <finance-overview
            :quote="quote"
            :opportunity="opportunity"
            :approvedChangeOrders="approvedChangeOrders"
          />
        </b-tab>
        <b-tab title="Tender" class="notprintable">
          <tender-export :address="opportunity.address"/>
        </b-tab>
      </b-tabs>
    </b-col>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BRow,
  BSpinner,
  BTable,
  BTooltip,
  VBModal,
  BBadge,
  BTab,
  BTabs,
  BCardText,
  BListGroup,
  BListGroupItem,
  VBToggle,
  BCollapse,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ccdc from "./Tabs/CanadianConstructionDocuments.vue";
import ChangeOrders from "./Tabs/ChangeOrders.vue";
import FinanceOverview from "./Tabs/FinanceOverview.vue";
import TenderExport from "./Tabs/TenderExport.vue";

export default {
  components: {
    BCard,
    BTable,
    BCol,
    BRow,
    BSpinner,
    BButton,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BTab,
    BTabs,
    BCardText,
    BListGroupItem,
    BCollapse,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BListGroup,
    BInputGroupPrepend,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    Ccdc,
    ChangeOrders,
    FinanceOverview,
    TenderExport,
  },
  props: [
    "opportunity",
    "tfrs",
    "revisionProp",
    "selectedRequirementsProp",
    "selectedIndexProp",
    "quote",
  ],
  directives: {
    "b-modal": VBModal,
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      opportunityId: this.$route.params.id,
    };
  },
  computed: {
    approvedChangeOrders() {
      const results = this.opportunity.change_orders
        .filter((x) => x.status === "Approved")
        .sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at));
      return results;
    },
  },
  mounted() {},
  methods: {},
};
</script>

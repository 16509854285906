<template>
  <div>
    <div v-if="changeOrders.length == 0">
      <b-col cols="12" style="text-align: center; padding-top: 15%">
        <h2>Create a Change Order</h2>
        <p style="font-weight: 400; font-size: 14px; line-height: 21px; color: #636363">
          Change order quotes handle changes in project scope after a contract has been
          signed.
        </p>
        <b-button v-b-modal.creation variant="outline-primary" style="margin-left: 10px">
          Create New Change Order
        </b-button>
      </b-col>
    </div>
    <div v-else>
      <b-col cols="12" style="text-align: end; padding-bottom: 20px">
        <b-button v-b-modal.creation variant="outline-primary" style="margin-left: 10px">
          Create New Change Order
        </b-button>
      </b-col>

      <b-tabs
        vertical
        nav-wrapper-class="nav-vertical"
        id="opportunityPage"
        active-nav-item-class="active-class-tab"
      >
        <template v-for="(item, index) in changeOrders">
          <b-tab
            :active="index == selectedIndex"
            v-bind:key="item.id"
            @click="selectedChangeOrder(item, index)"
            style="height: 95%"
          >
            <template #title>
              <b-row>
                <b-col align-self="start" cols="8">
                  <h5
                    style="
                      font-weight: 500;
                      font-size: 15px;
                      line-height: 24px;
                      color: #636363;
                    "
                  >
                    Change Order {{ item.change_order_number }}
                  </h5>
                  <div style="line-height: 20.4px; height: 30px" v-if="item.status">
                    <b-badge pill :class="badgeClass(item.status)">
                      {{ item.status }}
                    </b-badge>
                  </div>
                </b-col>
                <b-col
                  align-self="end"
                  class="align-items-center justify-content-end align-self-stretch"
                  cols="3"
                  style="padding-right: 0px"
                >
                </b-col>
                <b-row>
                  <b-col cols="12" align-self="start">
                    <b-col cols="12" align-self="start">
                      <template>
                        <template v-if="item.updated_at == item.created_at">
                          <b-avatar
                            size="30"
                            :variant="
                              item.created_by === userName
                                ? 'light-primary'
                                : 'light-secondary'
                            "
                            :text="initials(getQuoteLastUpdated(item))"
                            class="badge-minimal"
                          /><span
                            style="
                              font-weight: 400;
                              font-size: 12px;
                              line-height: 18px;
                              color: #b9b9c3;
                            "
                          >
                            Created
                            {{ formattedDate(item.created_at) }}</span
                          >
                        </template>
                        <template v-else>
                          <b-avatar
                            size="30"
                            :variant="
                              item.updated_by === userName
                                ? 'light-primary'
                                : 'light-secondary'
                            "
                            :text="initials(getQuoteLastUpdated(item))"
                            class="badge-minimal"
                          /><span
                            style="
                              font-weight: 400;
                              font-size: 12px;
                              line-height: 18px;
                              color: #b9b9c3;
                            "
                          >
                            Updated
                            {{ formattedDate(item.updated_at) }}</span
                          >
                        </template>
                      </template>
                    </b-col>
                  </b-col>
                </b-row>
              </b-row>
            </template>
            <b-card no-body style="max-height: 850px; height: 850px">
              <!-- <b-row style="margin-right: 0px;">
                        <b-col cols="8">
                          <div  style="padding-left:30px">
                              <div>
                                <p style="font-weight: 600;font-size: 21px;line-height: 21px;color: #636363;padding-top:40px">{{changeOrder.internal_id}}</p>
                                <p style="font-weight: 600;font-size: 21px;line-height: 21px;color: #636363;padding-top:40px">Change Order {{changeOrder.change_order_number}}</p>
                              </div>
                              <div style="padding-top:30px">
                                <p style="font-weight: 600;font-size: 15px;line-height: 24px;color: #636363;margin-bottom:10px">Quote</p>
                                <b-row style="margin-bottom:25px;" v-show="changeOrder.change_order_lines && (changeOrder.change_order_lines.length > 0)">
                                    <b-col md="6" align-self="center">
                                        <label style="font-weight: 600 !important;font-size: 12px;line-height: 23px;color: #B9B9C3;letter-spacing: 0.6px;text-transform: uppercase;">Quote Total</label>
                                        <h2 style="color: #6E6B7B;font-weight: 500 !important;font-size: 24px;line-height: 24px;">{{numberFormatter(changeOrder.subtotal + (opportunity.geography == 'Toronto' ? changeOrder.harmonized_sales_tax : changeOrder.quebec_sales_tax +  changeOrder.goods_and_service_tax )) }}</h2>
                                    </b-col>
                                </b-row>
                                <b-button :href="'/opportunities/' + opportunity.sf_opportunity_id + '/change-order/' + changeOrder.id + '/edit'" variant="outline-primary" v-if="changeOrder.change_order_lines && (changeOrder.change_order_lines.length == 0)">
                                    Create a Quote
                                </b-button>
                                <b-button  target="_blank" variant="outline-primary" v-else :href="'/opportunities/' + opportunity.sf_opportunity_id + '/change-order/' + changeOrder.id">
                                   View Quote
                                </b-button>
                                  <b-dropdown v-if="(isAdmin == 1) && ((changeOrder.quote_id != null))" no-caret toggle-class="p-0 pl-2" variant="link">
                                      <template #button-content>
                                          <feather-icon class="align-middle text-body" icon="MoreVerticalIcon" size="16" />
                                      </template>
                                      <b-dropdown-item target="_blank" @click="deleteQuote(changeOrder.quote_id)">
                                          <feather-icon icon="Trash2Icon" />
                                          <span class="align-middle ml-50">Delete</span>
                                      </b-dropdown-item>
                                  </b-dropdown>
                              </div>
                              <div style="padding-top:40px;padding-bottom:50px">
                                <p style="font-weight: 600;font-size: 15px;line-height: 24px;color: #636363;margin-bottom:10px">Attachments</p>
                                <template v-if="changeOrder.attachments_folder_url == 'https://'">
                                <p style="font-weight: 400;font-size: 14px;line-height: 21px;color: #B9B9C3;">No attachments to show.</p>
                                </template>
                                <b-button variant="outline-secondary" :href="changeOrder.attachments_folder_url" target="_blank" role="presentation" v-else>
                                  <feather-icon icon="ExternalLinkIcon" />
                                  View Attachments Folder
                                </b-button>
                              </div>
                              <hr>                            
                              <div style="padding-top:40px">
                                <p style="font-weight: 600;font-size: 15px;line-height: 24px;color: #636363;margin-bottom:10px">Context</p>
                                <span style="font-weight: 400;font-size: 14px;line-height: 21px;color: #6E6B7B;    word-break: break-word;white-space: pre-line;">{{changeOrder.reason}}</span>
                              </div>
                          </div>
                        </b-col>
                        <b-col cols="4" style="border: 1px solid rgba(31, 41, 47, 0.05);    padding-left: 0px;    padding-right: 0px;">
                              <h5 style="font-weight: 600;font-size: 15px;line-height: 24px;color: #636363;padding-top:20px;padding-left:28px;padding-bottom:15px;border: 1px solid rgba(31, 41, 47, 0.05);margin-bottom: 0px;">Requirements </h5>
                              <b-list-group style="position:relative; overflow-y:auto; height:750px">
                                <b-list-group-item
                                  v-for="(requirement, index) in selectedRequirements"
                                  :key="'req'+index"
                                  tag="li"
                                  style="border: 1px solid rgba(31, 41, 47, 0.05);padding-left: 28px;"
                                >
                                  <b-row>
                                    <b-col cols="2" style="padding-right:13px;font-weight: 400;font-size: 12px;line-height: 24px;color: #6E6B7B;">
                                      {{requirement.order}}
                                    </b-col>
                                    <b-col cols="9" style="padding-left:0px;">
                                      <div style="font-weight: 600;font-size: 14px;line-height: 21px;color: #636363;">{{ requirement.name }}</div>
                                      <div style="font-weight: 400;font-size: 12px;line-height: 18px;color: #636363;white-space: pre-line;">{{ requirement.description }}</div>
                                    </b-col>
                                  </b-row>
                                </b-list-group-item>
                              </b-list-group>
                        </b-col>
                    </b-row> -->

              <b-row style="margin-right: 0px">
                <b-col cols="9">
                  <div style="padding-left: 30px">
                    <p
                      style="
                        font-weight: 600;
                        font-size: 21px;
                        line-height: 21px;
                        color: #636363;
                        padding-top: 40px;
                      "
                    >
                      Change Order {{ changeOrder.change_order_number }}
                    </p>
                    <p
                      style="
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        color: #636363;
                        padding-top: 50px;
                      "
                    >
                      Change Order Overview
                    </p>
                    <p
                      style="
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #6e6b7b;
                      "
                    >
                      Reason for Change: <b>{{ changeOrder.type }}</b>
                    </p>
                    <p
                      style="
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 19px;
                        color: #6e6b7b;
                        white-space: pre-line;
                      "
                    >
                      {{ changeOrder.overview }}
                    </p>
                    <div style="padding-top: 45px">
                      <b-row
                        style="margin-bottom: 25px"
                        v-show="
                          changeOrder.change_order_lines &&
                          changeOrder.change_order_lines.length > 0
                        "
                      >
                        <b-col md="6" align-self="center">
                          <label
                            style="
                              font-weight: 600 !important;
                              font-size: 12px;
                              line-height: 23px;
                              color: #b9b9c3;
                              letter-spacing: 0.6px;
                              text-transform: uppercase;
                            "
                            >Quote Total</label
                          >
                          <h2
                            style="
                              color: #6e6b7b;
                              font-weight: 500 !important;
                              font-size: 24px;
                              line-height: 24px;
                            "
                          >
                            {{
                              numberFormatter(
                                changeOrder.admin_fee_enabled
                                  ? changeOrder.admin_fee_subtotal + changeOrder.subtotal
                                  : changeOrder.subtotal
                              )
                            }}
                          </h2>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>
                <b-col cols="3" style="padding-top: 30px">
                  <b-button
                    :href="
                      '/opportunities/' +
                      opportunity.sf_opportunity_id +
                      '/change-order/' +
                      changeOrder.id +
                      '/edit'
                    "
                    variant="primary"
                    v-if="
                      changeOrder.change_order_lines &&
                      changeOrder.change_order_lines.length == 0
                    "
                  >
                    Create CO Quote
                  </b-button>
                  <b-button
                    target="_blank"
                    variant="outline-primary"
                    v-else
                    :href="
                      '/opportunities/' +
                      opportunity.sf_opportunity_id +
                      '/change-order/' +
                      changeOrder.id
                    "
                  >
                    View Change Order
                  </b-button>
                  <!-- Dropdown -->
                  <b-dropdown v-if="isAdmin == 1" no-caret variant="link">
                    <template #button-content>
                      <feather-icon
                        class="align-middle text-body"
                        icon="MoreVerticalIcon"
                        size="16"
                      />
                    </template>

                    <b-dropdown-item target="_blank" @click="deleteCO()">
                      <feather-icon icon="Trash2Icon" />
                      <span class="align-middle ml-50">Delete (Admins Only)</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-card>
          </b-tab>
        </template>
      </b-tabs>
    </div>
    <!-- <b-modal id="creation" ref="creation" scrollable size="lg" title="Create Change Order " :hide-footer="true" :no-close-on-backdrop="true">
      <create :opp="opportunity" @errorModal="errorModal" :tfr="revisionProp" @changeOrderCreated="changeOrderCreated" />
  </b-modal> -->

    <b-modal
      id="creation"
      ref="creation"
      title="Create Change Order"
      @ok="handleOk"
      :centered="true"
      body-class="pt-2 pb-2 pl-3 pr-3"
      :no-close-on-backdrop="true"
      size="lg"
      :ok-disabled="modalIsBusy"
      :cancel-disabled="modalIsBusy"
      ok-title="Create Change Order"
      cancel-title="Cancel"
      cancel-variant="outline-secondary"
    >
      <h5>Summary</h5>
      <label style="padding-top: 10px">Reasons for Change</label>
      <v-select
        :clearable="false"
        v-model="contractPageForm.type"
        :options="reasonChangeOptions"
      ></v-select>
      <label style="padding-top: 30px">Change Order Overview</label>
      <b-form-textarea
        placeholder="Describe what is changing and why."
        :state="contractPageForm.overview.length <= 1000"
        rows="3"
        v-model="contractPageForm.overview"
      />
      <small
        class="textarea-counter-value float-right"
        :class="contractPageForm.overview.length > 1000 ? 'bg-danger' : ''"
      >
        <span class="char-count">{{ contractPageForm.overview.length }}</span> /
        {{ 1000 }}
      </small>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BRow,
  BSpinner,
  BTable,
  BTooltip,
  VBModal,
  BBadge,
  BTab,
  BTabs,
  BCardText,
  BListGroup,
  BListGroupItem,
  VBToggle,
  BCollapse,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import draggable from "vuedraggable";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";
import Create from "../../../../ChangeOrders/Components/ShortCreateModal.vue";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BTable,
    BCol,
    BRow,
    BSpinner,
    BButton,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BTab,
    BTabs,
    BCardText,
    BListGroupItem,
    BCollapse,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BListGroup,
    BInputGroupPrepend,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    Create,
    vSelect,
  },
  props: ["opportunity", "tfrs", "revisionProp"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      opportunityId: this.$route.params.id,
      userName: localStorage.getItem("name"),
      isAdmin: localStorage.getItem("isAdmin"),
      isBusy: true,
      tableIsBusy: true,
      testFitLink: "",
      testFitState: null,
      modalIsBusy: false,
      changeOrders: this.opportunity.change_orders,
      selectedIndex: 0,
      changeOrder: {},
      selectedRequirements: {},
      contractPageForm: {
        overview: "",
        type: "Client Directed Change",
        tfr_id: this.revisionProp.id,
      },
      items: [
        {
          label: "Client Directed Change",
          value: "Client Directed Change",
        },
        {
          label: "Included",
          value: true,
        },
      ],
      reasonChangeOptions: [
        "Client Directed Change",
        "Unforeseen Site Condition",
        "Design/Eng Error + Omission",
      ],
    };
  },
  beforeUpdate() {},
  mounted() {
    this.changeOrder = this.changeOrders[0];
    //this.selectedRequirements = this.changeOrders[0].requirements;
  },
  methods: {
    changeOrderCreated(variable) {
      this.showToast(
        "success",
        "Change Order Created Successfully",
        "Change Order Created"
      );
      this.$refs["creation"].hide();
      this.changeOrders = variable.change_orders;
      this.changeOrder = this.changeOrders[0];
    },
    deleteCO() {
      this.$http
        .delete(
          `/opportunities/${this.opportunity.sf_opportunity_id}/change-orders/${this.changeOrder.id}`
        )
        .then((response) => {
          this.showToast(
            "success",
            `Change Order ${this.changeOrder.id} has been successfully deleted.`,
            "Change Order Successfully"
          );
          this.changeOrders = response.data.data.change_orders;
          this.changeOrder = this.changeOrders[0];
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "Error Deleting CO",
            "Unable to delete CO at this time."
          );
          console.log(error);
        });
    },
    errorModal(variable) {
      this.showToast("danger", "Contact Digital for support.", "Something went wrong");
    },
    selectedChangeOrder(changeOrder, index) {
      this.changeOrder = changeOrder;
      this.selectedRequirements = changeOrder.requirements;
      this.selectedIndex = index;
    },
    initials(text) {
      const initial = text
        .match(/(\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();

      return initial;
    },
    getQuoteLastUpdated(item) {
      if (item.updated_by == null) {
        if (item.created_by == null) {
          return "Sales Force";
        }
        return item.created_by;
      }
      return item.updated_by;
    },    
    formattedDate(date) {
      return this.$dateF.formatDate(date);
    },
    badgeClass(status) {
      if (status == "Draft") {
        return "draft";
      } else if (status == "Approved") {
        return "approved";
      } else if (status == "Archived") {
        return "archived";
      } else if (status == "Quote In Progress") {
        return "quote-in-progress";
      } else if (status == "Sent to Client") {
        return "sent-to-client";
      }
    },
    numberFormatter(number) {
      return (
        (number < 0 ? "-" : "") +
        "$" +
        Number(Math.abs(number)).toLocaleString("EN-CA", { minimumFractionDigits: 2 })
      );
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.submitChangeOrder();
    },
    submitChangeOrder() {
      this.modalIsBusy = true;
      this.$http
        .post(`/opportunities/${this.opportunityId}/change-orders`, this.contractPageForm)
        .then((response) => {
          this.modalIsBusy = false;
          this.showToast(
            "success",
            `Change Order ${response.data.data.change_orders[0].change_order_number} has been created successfully.`,
            "Change Order Created"
          );
          this.$refs["creation"].hide();
          this.changeOrders = response.data.data.change_orders;
          this.changeOrder = this.changeOrders[0];
          this.contractPageForm = {
            overview: "",
            type: "Client Directed Change",
            tfr_id: this.revisionProp.id,
          };
        })
        .catch((error) => {
          this.modalIsBusy = false;
          this.$emit("errorModal");
        });
    },
  },
};
</script>
<style scoped></style>

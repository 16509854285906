<template>
<b-overlay :show="show" large rounded="lg" spinner-variant="primary">
  <div>

  </div>
      <template #overlay>
        <div class="text-center">
            <div class="loading">
              <div class="effect-1 effects" />
              <div class="effect-2 effects" />
              <div class="effect-3 effects" />
            </div>
        </div>
    </template>
</b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BCardText,
  BInputGroup,
  BInputGroupPrepend,
  BTable,
  BOverlay,
  BFormTextarea,
  BButton,
  BListGroupItem,
  VBToggle,
  BCard,
  BCollapse
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormFile,
    BCardText,
    BInputGroup,
    BInputGroupPrepend,
    BTable,
    BOverlay,
    BFormTextarea,
    BButton,
    draggable,
    BListGroupItem,
    AppCollapse,
    AppCollapseItem,
    BCard,
    BCollapse
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  mixins: [heightTransition],
  props: ['opp','tfr'],
  data() {
        return {
            revision:'',
            rentable_sqft: this.opp.rentable_sqft,
            address: this.opp.address,
            contact_name: this.opp.contact_name,
            contact_phone: this.opp.contact_phone,
            contact_email: this.opp.contact_email,
            sf_opportunity_id: this.opp.sf_opportunity_id,
            requirements: [],
            newRequirement:{
              name:'',
              description:''
            },
            requirementName:'',
            requirementDescription:'',
            nextTodoOrder: 1,
            notes:'',
            attachments_folder:'',
            arrayLenghtError:false,
            maxChar: 500,
            show: false,
        }
  },
  
  computed: {
    addRequirementEnabled(){
      if((this.newRequirement.name == '') || (this.newRequirement.description == '') ){
        return true
      }else{
        return false
      }
    },
    incompleteRequirement(){
      var obj = [];
      obj = this.requirements.filter(item => (item.name == '' || item.description == ''));
      if(obj.length > 0){
        return true
      }else{
        return false
      }
    }
  },
  mounted() {
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    formSubmitted() {
      return new Promise((resolve, reject) => {
        this.$refs.contextRules.validate().then(success => {
          if (success) {
            if(this.notes.length > 1000){
              reject()
            }
            resolve(true)
            this.show = true
            this.$http.post(`/opportunities/${this.sf_opportunity_id}/change-orders`, {
              requirements: this.requirements,
              notes: this.notes,
              attachments_folder : this.addhttp(this.attachments_folder),
              tfr_id : this.tfr.id,
            })
            .then(response => {
                this.show = false
                this.$emit('changeOrderCreated', response.data.data)
            })
            .catch(error => {
                this.show = false
                this.$emit('errorModal')
            })
          } else {
            reject()
          }
        })
      })
      
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationRequirementsTab() {
      return new Promise((resolve, reject) => {
        this.$refs.requirementsRules.validate().then(success => {
          if(this.incompleteRequirement){
            reject()
          }
          if((this.requirements.length == 0)){
              this.arrayLenghtError = true;
          }
          if (success) {
            if((this.requirements.length > 0)){
              this.arrayLenghtError = false;
              resolve(true)
            }
            if((this.requirements.length == 0)){
              this.arrayLenghtError = true;
              reject()
            }
          } else {
             reject()
          }
        })
      })
    },
    validationContextTab() {
      return new Promise((resolve, reject) => {
        this.$refs.contextRules.validate().then(success => {
          if (success) {
            if(this.notes.length > 1000){
              reject()
            }
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    onChange(event) {
        this.reorder()
    },
    reorder() {
        this.requirements.forEach((item, index) => (item.order = (index + 1)))
    },
    addhttp(url) {
      if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
          url = "https://" + url;
      }
      return url;
    }
  },
}
</script>
<style scoped>

</style>
<template>
  <div>
    <div class="container modal_text_color px-2 pb-1">
      <b-row class="mt-0">
        <b-col cols="12" class="py-0 my-0">
          <p>
            The selected revision will be handed off to delivery teams. This action cannot
            be reverted - please ensure this is the correct revision.
          </p>
        </b-col>
        <b-col cols="12" class="py-0 my-0">
          <label for="handover_select"> Select revision for handover </label>
        </b-col>
        <b-col cols="12" class="py-0 my-0 mb-1">
          <v-select
            v-model="selected"
            :options="select_options"
            item-value="value"
            item-text="text"
            label="text"
            :clearable="false"
            @input="setTotals()"
          ></v-select>
        </b-col>
        <b-col cols="6" class="py-0 my-0">
          <label class="h5 total_p mb-0" for="total">Project Total</label>
          <p>
            ${{
              totals.project.toLocaleString("en-CA", {
                minimumFractionDigits: 2,
              })
            }}
          </p>
        </b-col>
        <b-col cols="6" class="py-0 my-0">
          <label class="h5 total_p mb-0" for="total">Price/SQFT</label>
          <p>
            ${{
              totals.sqft.toLocaleString("en-CA", {
                minimumFractionDigits: 2,
              })
            }}
          </p>
        </b-col>
      </b-row>
      <hr />
      <div class="d-flex justify-content-end">
        <b-button @click="cancel" class="ml-2" variant="outline-secondary">Cancel</b-button>
        <b-button
          @click="sentAsWon"
          :disabled="selected.value == null"
          class="ml-2"
          variant="primary"
          >Submit</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { BFormSelect, BRow, BCol, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BFormSelect,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  props: {
    won_tfr: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      selected: { value: null, text: "Select Revision" },
      select_options: [{ value: null, text: "Select Revision" }],
      totals: {
        project: 0,
        sqft: 0,
      },
    };
  },
  mounted() {
    this.initForm();
  },
  methods: {
    initForm() {
      let vm = this;
      if (vm.won_tfr.length > 0) {
        vm.won_tfr.map((w) => {
          vm.select_options.push({
            value: w.quote_id,
            text: w.tfr,
            ...w,
          });
        });
      }
    },
    sentAsWon() {
      this.$emit("wonAction", this.selected);
    },
    cancel() {
      this.selected = { value: null, text: "Select Revision" };
      this.$emit("closeAction");
    },
    setTotals() {
      let vm = this;
      if (vm.selected.value != null) {
        vm.totals.project = vm.selected.quote_totals.project_total;
        vm.totals.sqft = vm.selected.quote_totals.project_dollar_per_sqft;
      } else {
        vm.totals.project = vm.totals.sqft = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_text_color {
  color: #5e5873;
}
.total_p {
  color: #b9b9c3;
  font-size: 16px;
}
</style>
